<template>

  <svg role="presentation" focusable="false" width="24" height="24" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20 1.5C10.833 1.5 3.333 9 3.333 18.167c0 5.333 2.5 10.166 6.5 13.166.834-.833 2-1.5 3.334-2A15.576 15.576 0 018.5 24.667h4.833V24c0-2 .834-3.833 2.334-5v-.833c0-1.167.166-2.334.333-3.5h8.167c.166 1.166.333 2.333.333 3.5V19c1.5 1.167 2.333 3 2.333 5v.667h4.834c-1.167 2-2.667 3.5-4.667 4.666 1.333.5 2.5 1.167 3.333 2 4-3 6.5-7.833 6.5-13.166C36.667 9 29.167 1.5 20 1.5zm-7.167 20.167H7a12.688 12.688 0 01-.5-3.5c0-1.167.167-2.334.5-3.5h5.833c-.166 1.166-.166 2.333-.166 3.5 0 1.166.166 2.333.166 3.5zm.5-10h-5c1.667-2.834 4.334-5 7.334-6-1 1.833-1.834 3.833-2.334 6zm3.5 0C17.5 9.333 18.667 7 20 5c1.333 2 2.5 4.167 3.167 6.667h-6.334zm9.834 0c-.5-2-1.334-4-2.334-6 3 1 5.667 3.166 7.167 6h-4.833zm.5 10c.166-1.167.166-2.334.166-3.5 0-1.167-.166-2.334-.166-3.5H33c.333 1.166.5 2.333.5 3.5 0 1.166-.167 2.333-.5 3.5h-5.833z"/><path clip-rule="evenodd" d="M25 24c0 2.333-1.5 4.167-3.5 4.833C21 29 20.5 29 20 29s-1 0-1.5-.167c-2-.666-3.5-2.5-3.5-4.833 0-1 .333-2 .833-2.833C16.667 19.833 18.333 19 20 19c1.667 0 3.333.833 4.167 2.167C24.667 22 25 23 25 24zM30 34.667v3.666H10v-3.666c0-.834.5-1.667 1.167-2.334 1-.833 2.5-1.5 4-2 .166 0 .166 0 .333-.166 1.167-.334 2.5-.5 3.5-.667h2c1 0 2.333.333 3.5.667.167 0 .167 0 .333.166 1.5.5 3 1.167 4 2C29.5 33 30 33.667 30 34.667z"/></svg>

</template>


<script>

  export default {"name":"icon-82954fc49c0116cddd3350b828e20f6b"}

</script>