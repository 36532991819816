<template>

  <svg role="presentation" focusable="false" width="24" height="24" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M28.333 13.333c-15 3.334-18.5 13.617-21.966 22.234l3.15 1.1 1.583-3.834c.8.284 1.633.5 2.233.5C31.667 33.333 36.667 5 36.667 5 35 8.333 23.333 8.75 15 10.417 6.667 12.083 3.333 19.167 3.333 22.5s2.917 6.25 2.917 6.25c5.417-15.417 22.083-15.417 22.083-15.417z"/></svg>

</template>


<script>

  export default {"name":"icon-0f4a755e1d0f91594917c14ec898fc22"}

</script>