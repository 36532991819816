<template>

  <svg role="presentation" focusable="false" width="24" height="24" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M32.317 17.9L18.333 31.884v4.783H10a3.322 3.322 0 01-3.333-3.334V6.668A3.332 3.332 0 0110 3.334h1.667V15l4.166-2.5L20 15V3.334h10a3.332 3.332 0 013.333 3.333v10.5c-.366.2-.716.433-1.016.733zm-10.65 15.367v3.4h3.4l10.216-10.2-3.4-3.417-10.216 10.217zM38.083 22.45l-2.2-2.2c-.333-.333-.883-.333-1.2 0l-1.633 1.634 3.4 3.4 1.633-1.634c.334-.316.334-.866 0-1.2z"/></svg>

</template>


<script>

  export default {"name":"icon-5005657db6687c0e7b43d0e584bfcbc1"}

</script>