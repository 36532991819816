<template>

  <svg role="presentation" focusable="false" width="24" height="24" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15 5v25h5V5h-5zm5 3.333L26.667 30l5-1.667L25 6.667l-5 1.666zm-11.667 0V30h5V8.333h-5zM5 31.667V35h30v-3.333H5z"/></svg>

</template>


<script>

  export default {"name":"icon-e64d7f75f6109b272a81298ce6e088e1"}

</script>