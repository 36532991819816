<template>

  <svg viewBox="0 0 24 24" role="presentation" focusable="false" width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.8 15.9l4.67-3.5c.27-.2.27-.6 0-.8L10.8 8.1a.5.5 0 00-.8.4v7c0 .41.47.65.8.4zM12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" fill="#3938A5"/></svg>

</template>


<script>

  export default {"name":"icon-4b3711e90ae5092501b8177dc8eb4988"}

</script>