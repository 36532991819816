<template>

  <svg viewBox="0 0 24 24" role="presentation" focusable="false" width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5 11h4c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2H5c-1.1 0-2 .9-2 2v4c0 1.1.9 2 2 2z" fill="#3938A5"/><path d="M5 21h4c1.1 0 2-.9 2-2v-4c0-1.1-.9-2-2-2H5c-1.1 0-2 .9-2 2v4c0 1.1.9 2 2 2z" fill="#5DB2C4"/><path d="M13 5v4c0 1.1.9 2 2 2h4c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2h-4c-1.1 0-2 .9-2 2z" fill="#ED2828"/><path d="M15 21h4c1.1 0 2-.9 2-2v-4c0-1.1-.9-2-2-2h-4c-1.1 0-2 .9-2 2v4c0 1.1.9 2 2 2z" fill="#FF8B41"/></svg>

</template>


<script>

  export default {"name":"icon-b7eb13d60b21674642d5409cdc8a5f22"}

</script>