<template>

  <svg role="presentation" focusable="false" width="24" height="24" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M21.667 5c6.466 0 11.666 5.233 11.666 11.666 0 4.667-2.716 8.65-6.666 10.517V35H15v-5h-1.667A3.322 3.322 0 0110 26.666v-5H7.5c-.7 0-1.1-.833-.7-1.35L10 16.1C10.317 9.916 15.383 5 21.667 5zm0-3.333c-7.65 0-13.984 5.7-14.9 13.166l-2.6 3.5h-.05l-.034.05a4.12 4.12 0 00-.316 4.317 4.222 4.222 0 002.9 2.2v1.767c0 3.083 2.133 5.7 5 6.45v5.216H30v-9.166c4.167-2.784 6.667-7.4 6.667-12.5 0-8.284-6.734-15-15-15zm6.666 13.05c0 2.566-2.266 4.616-5.7 7.733l-.966.883-.967-.883c-3.433-3.117-5.7-5.167-5.7-7.733 0-2 1.6-3.65 3.6-3.667h.067c1.15 0 2.25.516 3 1.383a3.99 3.99 0 013-1.383c2-.017 3.666 1.6 3.666 3.6v.066z"/></svg>

</template>


<script>

  export default {"name":"icon-1b409d3de15c5b093494358aaf3c2cd1"}

</script>