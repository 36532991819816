<template>

  <svg viewBox="0 0 24 24" role="presentation" focusable="false" width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3 17.8v3.9a.3.3 0 00.3.3h17.4a.3.3 0 00.3-.3V4.3a.3.3 0 00-.3-.3h-3.9a.3.3 0 00-.3.3v3.9a.3.3 0 01-.3.3h-3.9a.3.3 0 00-.3.3v3.9a.3.3 0 01-.3.3H7.8a.3.3 0 00-.3.3v3.9a.3.3 0 01-.3.3H3.3a.3.3 0 00-.3.3z"/></svg>

</template>


<script>

  export default {"name":"icon-0d8381d750ee8e0370e48e8a05d657ae"}

</script>