<template>

  <svg viewBox="0 0 24 24" role="presentation" focusable="false" xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z"/></svg>

</template>


<script>

  export default {"name":"icon-457a48dc233908740fbdd70205e7faf6"}

</script>