<template>

  <svg viewBox="0 0 24 24" role="presentation" focusable="false" xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"/></svg>

</template>


<script>

  export default {"name":"icon-9f49ecb963b064331ac927c96bfb358d"}

</script>