<template>

  <svg viewBox="0 0 24 24" role="presentation" focusable="false" width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.99 6.91c.39.38 1.03.38 1.41 0a.996.996 0 000-1.41l-.71-.71A.996.996 0 105.28 6.2l.71.71zM5 12c0-.55-.45-1-1-1H3c-.55 0-1 .45-1 1s.45 1 1 1h1c.55 0 1-.45 1-1zm16 1c.55 0 1-.45 1-1s-.45-1-1-1h-1c-.55 0-1 .45-1 1s.45 1 1 1h1zm-2.99-6.09l.71-.71a.996.996 0 10-1.41-1.41l-.71.71a.996.996 0 101.41 1.41zM15 15.98c1.21-.92 2-2.35 2-3.98 0-3.11-2.84-5.56-6.07-4.88-1.94.4-3.48 2-3.84 3.95-.37 2.01.48 3.83 1.91 4.91h6zM12 5c.55 0 1-.45 1-1V3c0-.55-.45-1-1-1s-1 .45-1 1v1c0 .55.44 1 1 1zM12 22c1.1 0 2-.9 2-2h-4c0 1.1.9 2 2 2zm-2.25-5c-.412 0-.75.45-.75 1s.338 1 .75 1h4.5c.412 0 .75-.45.75-1s-.338-1-.75-1h-4.5z" fill="#F8C529"/></svg>

</template>


<script>

  export default {"name":"icon-2af871a6dc6f98a227077c8902e56c00"}

</script>