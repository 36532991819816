<template>

  <svg viewBox="0 0 24 24" role="presentation" focusable="false" width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5 4.5V6H2v2h3v3h2V8h3V6H7V3H5v1.5m12.664-1.437a1.005 1.005 0 00-.533.448c-.095.161-.111.233-.111.489s.016.328.111.489a.993.993 0 001.57.212c.18-.18.299-.459.299-.701 0-.674-.705-1.168-1.336-.937M14 7v1h8V6h-8v1m3.664 2.063a1.005 1.005 0 00-.533.448c-.095.161-.111.233-.111.489s.016.328.111.489a.993.993 0 001.57.212c.18-.18.299-.459.299-.701 0-.674-.705-1.168-1.336-.937M14.83 14.83l-.71.711 1.06 1.059 1.06 1.059-1.06 1.061-1.06 1.061.71.709.711.71 1.059-1.06 1.059-1.06 1.061 1.06 1.061 1.06.709-.71.71-.711-1.06-1.059-1.06-1.059 1.06-1.061 1.06-1.061-.71-.709-.711-.71-1.059 1.06-1.059 1.06-1.061-1.06-1.061-1.06-.709.71M2 18v1h8v-2H2v1"/></svg>

</template>


<script>

  export default {"name":"icon-3e710093e9a543dbed71a32931f1158c"}

</script>