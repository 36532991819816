<template>

  <svg viewBox="0 0 24 24" role="presentation" focusable="false" width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18.41 5.799l-1.21-1.21c-.78-.78-2.05-.78-2.83 0l-2.68 2.68L3 15.959v4.04h4.04l8.74-8.74 2.63-2.63c.79-.78.79-2.05 0-2.83zm-12.2 12.2H5v-1.21l8.66-8.66 1.21 1.21-8.66 8.66zm4.79 2l4-4h6v4H11z"/></svg>

</template>


<script>

  export default {"name":"icon-9444a18bb48cd6165a59382657e22d55"}

</script>