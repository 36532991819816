<template>

  <svg viewBox="0 0 24 24" role="presentation" focusable="false" width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3.488 7.598l3.924-4.105c.3-.314.715-.493 1.146-.493h6.45c.894 0 1.667.39 1.667 1.325l-7.967 7.807a.5.5 0 00-.15.357v4.007a.5.5 0 00.5.5h4.052a.5.5 0 00.35-.142l3.215-3.144-.009 5.946c0 .935-.723 1.7-1.616 1.7H4.625c-.894 0-1.625-.765-1.625-1.7l.008-10.85c0-.451.17-.884.48-1.207zm4.395.956c.447 0 .813-.383.813-.85V3.879l-4.47 4.675h3.657z" fill="#5DB2C4" fill-opacity=".5"/><path d="M10.008 13.213v2.053c0 .189.149.338.338.338h2.052a.317.317 0 00.237-.102l7.373-7.366-2.532-2.532-7.367 7.366a.331.331 0 00-.1.243zm11.8-6.856a.678.678 0 000-.96l-1.593-1.594a.678.678 0 00-.96 0l-1.247 1.246 2.554 2.555 1.247-1.247z" fill="#5DB2C4"/></svg>

</template>


<script>

  export default {"name":"icon-70e202e91a9728452aa463c61672fa14"}

</script>