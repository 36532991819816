<template>

  <svg viewBox="0 0 24 24" role="presentation" focusable="false" xmlns="http://www.w3.org/2000/svg" width="24" height="24"><circle cx="12" cy="12" r="8"/></svg>

</template>


<script>

  export default {"name":"icon-4787b1bde265df2180637a08fe51264e"}

</script>