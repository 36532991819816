<template>

  <svg role="presentation" focusable="false" width="24" height="24" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20 10a10 10 0 0110 10c0 3.7-2.017 6.933-5 8.666v3a1.666 1.666 0 01-1.667 1.667h-6.666A1.667 1.667 0 0115 31.667v-3C12.017 26.932 10 23.7 10 20a10 10 0 0110-10zm3.333 25v1.666a1.666 1.666 0 01-1.666 1.667h-3.334a1.666 1.666 0 01-1.666-1.666V35h6.666zm10-16.667h5v3.334h-5v-3.334zm-31.666 0h5v3.334h-5v-3.334zm20-16.666v5h-3.334v-5h3.334zM8.2 5.832L11.75 9.4l-2.367 2.35-3.55-3.534L8.2 5.833zm20.05 3.55l3.533-3.55 2.384 2.383-3.55 3.534-2.367-2.367z"/></svg>

</template>


<script>

  export default {"name":"icon-6cdea14aa8a8809d9db36407afc04f45"}

</script>