<template>

  <svg viewBox="0 0 24 24" role="presentation" focusable="false" width="22" height="22" fill="none" xmlns="http://www.w3.org/2000/svg"><path clip-rule="evenodd" d="M3.901 7.031c-2.092 2.718-1.773 6.126-.804 8.595a.986.986 0 01-1.836.721c-1.1-2.803-1.608-7.032 1.077-10.52C5.018 2.35 10.614-.047 20.898.183a.986.986 0 01.964.963c.109 4.583-.796 10.94-3.58 15.269-1.406 2.188-3.341 3.927-5.927 4.573-2.423.605-5.259.21-8.532-1.413L1.85 21.546a.986.986 0 11-1.395-1.395l2.29-2.29c.043-.045.09-.093.128-.13a3.207 3.207 0 01.094-.092l3.459-3.46.18-5.36a.986.986 0 111.972.067l-.11 3.252 5.849-5.849a.986.986 0 011.395 1.395l-2.824 2.824 2.16.097a.986.986 0 01-.088 1.97l-3.963-.177-5.695 5.695c2.701 1.225 4.86 1.408 6.574.98 1.96-.49 3.523-1.823 4.747-3.726 2.304-3.583 3.237-8.935 3.273-13.207C10.48 2.082 5.934 4.392 3.9 7.031zm.028 12.436l.032-.031z"/></svg>

</template>


<script>

  export default {"name":"icon-212163781b7bf8e256a13db83dd2172b"}

</script>