<template>

  <svg role="presentation" focusable="false" width="24" height="24" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_6280:84308)"><path d="M23.25 22.017c2.31 0 4.183-2.239 4.183-5 0-2.762-1.873-5-4.183-5-2.31 0-4.183 2.238-4.183 5 0 2.761 1.873 5 4.183 5zM16.583 20.633h-5v3.334h5v-3.334zM34.917 10.617h-5v3.333h5v-3.333z"/><path d="M33.333 35.2H0V13.45h3.333v18.417h30V35.2z"/><path d="M36.667 8.683V25.35H9.833V8.683h26.834zM40 5.35H6.5v23.333H40V5.35z"/></g><defs><clipPath id="clip0_6280:84308"><path fill="#fff" d="M0 0h40v40H0z"/></clipPath></defs></svg>

</template>


<script>

  export default {"name":"icon-516631fd3c42e40512b437dc45448a93"}

</script>