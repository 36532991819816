<template>

  <svg role="presentation" focusable="false" width="24" height="24" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M36.183 14.517c2.084-2.084 1.134-4.517 0-5.7l-5-5c-2.1-2.084-4.516-1.134-5.7 0l-2.833 2.85h-4.317c-3.166 0-5 1.666-5.933 3.583L5 17.65v6.667l-1.183 1.166c-2.084 2.1-1.134 4.517 0 5.7l5 5c.9.9 1.866 1.234 2.783 1.234 1.183 0 2.267-.584 2.917-1.234l4.5-4.516H25c2.833 0 4.267-1.767 4.783-3.5 1.884-.5 2.917-1.934 3.334-3.334 2.583-.666 3.55-3.116 3.55-4.833v-5h-.984l.5-.483zM33.333 20c0 .75-.316 1.667-1.666 1.667H30v1.666c0 .75-.317 1.667-1.667 1.667h-1.666v1.667c0 .75-.317 1.666-1.667 1.666h-7.35L12.183 33.8c-.516.483-.816.2-1 .017L6.2 28.85c-.483-.517-.2-.817-.017-1l2.15-2.167v-6.666l3.334-3.334v2.65c0 2.017 1.333 5 5 5 3.666 0 5-2.983 5-5h11.666V20zm.484-7.85L30.983 15h-12.65v3.333c0 .75-.316 1.667-1.666 1.667-1.35 0-1.667-.917-1.667-1.667v-5c0-.766.283-3.333 3.333-3.333h5.684l3.8-3.8c.516-.483.816-.2 1-.017L33.8 11.15c.483.517.2.817.017 1z"/></svg>

</template>


<script>

  export default {"name":"icon-3ba763d550e60237bd374541817c3e35"}

</script>