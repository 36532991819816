<template>

  <svg role="presentation" focusable="false" width="24" height="24" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M21.667 14.283a2.383 2.383 0 10-.001 4.766 2.383 2.383 0 000-4.766z"/><path d="M21.667 5c-6.25 0-11.334 4.9-11.634 11.067l-3.2 4.266c-.416.55-.016 1.334.667 1.334H10v5C10 28.5 11.5 30 13.333 30H15v5h11.667v-7.8c3.933-1.867 6.666-5.883 6.666-10.533C33.333 10.217 28.117 5 21.667 5zm5 11.667c0 .216-.017.433-.034.65l1.384 1.1c.133.1.166.266.083.416l-1.333 2.317a.335.335 0 01-.4.15l-1.65-.667c-.35.267-.717.484-1.117.65l-.267 1.767c-.016.167-.166.283-.333.283h-2.667A.339.339 0 0120 23.05l-.25-1.767a4.763 4.763 0 01-1.133-.65l-1.65.667c-.15.05-.334 0-.417-.15l-1.333-2.317a.316.316 0 01.083-.416l1.4-1.1a8.472 8.472 0 01-.033-.65c0-.217.033-.45.066-.65l-1.416-1.1c-.134-.1-.167-.267-.084-.434l1.334-2.3c.083-.15.25-.2.4-.15l1.666.667c.334-.25.717-.483 1.117-.65l.25-1.767a.339.339 0 01.333-.283H23c.167 0 .3.117.333.283l.25 1.767c.4.167.767.383 1.117.65l1.667-.667c.15-.05.333 0 .4.15l1.333 2.3c.083.15.05.334-.083.434l-1.417 1.1c.05.2.067.416.067.65z"/></svg>

</template>


<script>

  export default {"name":"icon-ac383293e7fff352dba4045a5353e67f"}

</script>