<template>

  <svg viewBox="0 0 24 24" role="presentation" focusable="false" xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path d="M18 8H8v10H6V8a2 2 0 012-2h10v2m-4-6H4a2 2 0 00-2 2v10h2V4h10V2m8 10v8a2 2 0 01-2 2h-8a2 2 0 01-2-2v-8a2 2 0 012-2h8a2 2 0 012 2m-2 3h-3v-3h-2v3h-3v2h3v3h2v-3h3v-2z"/></svg>

</template>


<script>

  export default {"name":"icon-4e8a230ef43d260f270af7f90e4d862b"}

</script>