<template>

  <svg viewBox="0 0 24 24" role="presentation" focusable="false" xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path d="M10 18h4v-2h-4v2zM3 6v2h18V6H3zm3 7h12v-2H6v2z"/></svg>

</template>


<script>

  export default {"name":"icon-f5ca3a0cf50d2a9facf82ec2c88d0fac"}

</script>