<template>

  <svg viewBox="0 0 24 24" role="presentation" focusable="false" width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><g fill="#3938a5"><path d="M1.993 16.364h12.926v1.587H1.993zM2.89 7.218v1.693h2.662c.506 0 .888.364.888.846H4.665c-1.456 0-2.663 1.15-2.663 2.538 0 1.396 1.207 2.539 2.663 2.539h3.55V9.757c0-1.388-1.198-2.539-2.663-2.539m-.887 4.231H6.44v1.692H4.665c-.506 0-.888-.364-.888-.846s.382-.846.888-.846z"/></g><path d="M7.687 5.385h3.248v9.45H9.311l.005-7.628H7.692zM11.625 5.369h3.248v9.467h-1.624l-.02-7.63h-1.624z" fill="#b30f0f"/><path d="M15.599 7.195l2.43 6.324c-.372.973-.524 1.795-.881 2.219-.386.477-1.303.623-1.545.623l.002 1.592c.973.037 2.28-.712 2.888-1.658.43-.507 3.514-9.1 3.514-9.1H20.44l-1.628 4.363-1.644-4.363H15.6" fill="#3938a5"/></svg>

</template>


<script>

  export default {"name":"icon-40bcababd163c5d974599a8102319c63"}

</script>