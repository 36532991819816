<template>

  <svg role="presentation" focusable="false" width="24" height="24" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M33.333 8.333v23.334H6.667V8.333h26.666zm0-3.333H6.667a3.322 3.322 0 00-3.334 3.333v23.334A3.322 3.322 0 006.667 35h26.666a3.322 3.322 0 003.334-3.333V8.333A3.322 3.322 0 0033.333 5zM30 25H10v3.333h20V25zM16.667 11.667H10v10h6.667v-10zM20 15h10v-3.333H20V15zm10 3.333H20v3.334h10v-3.334z"/></svg>

</template>


<script>

  export default {"name":"icon-1217b02ff5d63578701605a41c8b9e06"}

</script>