<template>

  <svg role="presentation" focusable="false" width="24" height="24" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20 18.333a1.667 1.667 0 110 3.334 1.667 1.667 0 010-3.334zM7.033 7.033C9.417 4.65 14.583 5.717 20 9.267c5.417-3.55 10.583-4.617 12.967-2.234 2.383 2.384 1.316 7.55-2.234 12.967 3.55 5.417 4.617 10.583 2.234 12.967-2.384 2.383-7.55 1.316-12.967-2.234-5.417 3.55-10.583 4.617-12.967 2.234C4.65 30.583 5.717 25.417 9.267 20 5.717 14.583 4.65 9.417 7.033 7.033zM25.9 14.1a48.256 48.256 0 012.817 3.133c2.3-3.55 3.133-6.6 1.883-7.833-1.233-1.25-4.283-.417-7.833 1.883 1.05.867 2.1 1.8 3.133 2.817zM14.1 25.9a48.227 48.227 0 01-2.817-3.133c-2.3 3.55-3.133 6.6-1.883 7.833 1.233 1.25 4.283.417 7.833-1.883-1.05-.867-2.1-1.8-3.133-2.817zM9.4 9.4c-1.25 1.233-.417 4.283 1.883 7.833.867-1.05 1.8-2.1 2.817-3.133a48.254 48.254 0 013.133-2.817c-3.55-2.3-6.6-3.133-7.833-1.883zm7.067 14.133A46.875 46.875 0 0020 26.717a46.857 46.857 0 003.533-3.184A46.857 46.857 0 0026.717 20a46.875 46.875 0 00-3.184-3.533A46.835 46.835 0 0020 13.283a46.854 46.854 0 00-3.533 3.184A46.854 46.854 0 0013.283 20a46.835 46.835 0 003.184 3.533zM30.6 30.6c1.25-1.233.417-4.283-1.883-7.833-.867 1.05-1.8 2.1-2.817 3.133a48.229 48.229 0 01-3.133 2.817c3.55 2.3 6.6 3.133 7.833 1.883z"/></svg>

</template>


<script>

  export default {"name":"icon-be280362ebdba890d4e1e1f5b70be769"}

</script>