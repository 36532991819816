<template>

  <svg role="presentation" focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M21.13 4H4.87a.87.87 0 00-.87.87V10h2V6h14v11h-1v2h2.13a.87.87 0 00.87-.87V4.87a.87.87 0 00-.87-.87zM8 14a2 2 0 112 2 2 2 0 01-2-2zm6 4.51V20H6v-1.49c0-1.34 2.67-2 4-2s4 .66 4 2zm0-3.35a6.36 6.36 0 00-.92.08 1.77 1.77 0 01-.23.48c1.32.49 2.48 1.37 2.48 2.79V19H18v-1.84c0-1.32-2.67-2-4-2zm0-4.49a2 2 0 00-1.68.93 3.34 3.34 0 011 2.4 3 3 0 010 .53 1.7 1.7 0 00.71.14 2 2 0 000-4zm-6.32.93A2 2 0 004 12.67a2 2 0 002 2 1.7 1.7 0 00.71-.14 3 3 0 010-.53 3.34 3.34 0 01.97-2.4zm-.76 3.64a6.36 6.36 0 00-.92-.08c-1.33 0-4 .68-4 2V19h2.67v-.49c0-1.42 1.16-2.3 2.48-2.79a1.77 1.77 0 01-.23-.48z"/></svg>

</template>


<script>

  export default {"name":"icon-7c4a73cf4e87719a982d3373c042f56e"}

</script>