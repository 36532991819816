<template>

  <svg role="presentation" focusable="false" width="24" height="24" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.833 4.85v2.733l3.334 3.334V8.183h16.5v23.234a1.667 1.667 0 11-3.334 0V26.55H15v-4.1l-3.333-3.333v7.433h-5v6.667A3.233 3.233 0 0010 36.4h21.5a3.533 3.533 0 003.5-3.533V4.85H11.833zM7.3 5.417a.883.883 0 00-1.283 0L3.883 7.534a.917.917 0 000 1.283l1.667 1.666L8.967 7.15"/><path d="M20 18.1v3.417h-3.417L15.167 20.1l-8.65-8.65 3.416-3.4 1.9 1.9 3.334 3.317L20 18.1z"/></svg>

</template>


<script>

  export default {"name":"icon-4508af2bea3170b87c7a506f02f551c3"}

</script>