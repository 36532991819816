<template>

  <svg role="presentation" focusable="false" width="24" height="24" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5 28.75V35h6.25l18.433-18.45-6.25-6.25L5 28.75zm32.683 1.85L30.6 37.684l-8.667-8.667 2.95-2.95 1.667 1.667 4.117-4.134 2.366 2.367-2.433 2.367L32.367 30l2.366-2.333 2.95 2.933zM11.017 18.05l-8.7-8.65L9.4 2.317l2.933 2.95L8.217 9.4 10 11.167l4.1-4.133L16.467 9.4 14.1 11.75l1.667 1.667-4.75 4.633zm23.5-6.383c.65-.65.65-1.667 0-2.35l-3.9-3.833c-.617-.65-1.7-.65-2.35 0L25.2 8.534l6.25 6.25 3.067-3.117z"/></svg>

</template>


<script>

  export default {"name":"icon-d7ad3d65ca1b23fe028dee3e0843f46d"}

</script>