<template>

  <svg viewBox="0 0 24 24" role="presentation" focusable="false" xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path d="M7 10l5 5 5-5z"/></svg>

</template>


<script>

  export default {"name":"icon-29d7e51243a8f0a236f4e3e2aaaa40bd"}

</script>