<template>

  <svg viewBox="0 0 24 24" role="presentation" focusable="false" xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path d="M17 18l-5-2.18L7 18V5h10m0-2H7a2 2 0 00-2 2v16l7-3 7 3V5a2 2 0 00-2-2z"/></svg>

</template>


<script>

  export default {"name":"icon-a71c25353dd2eefd8789cb19f1dbc716"}

</script>