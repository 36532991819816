<template>

  <svg viewBox="0 0 24 24" role="presentation" focusable="false" width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20.5 11H19V7c0-1.1-.9-2-2-2h-4V3.5a2.5 2.5 0 00-5 0V5H4c-1.1 0-1.99.9-1.99 2v3.8H3.5c1.49 0 2.7 1.21 2.7 2.7 0 1.49-1.21 2.7-2.7 2.7H2V20c0 1.1.9 2 2 2h3.8v-1.5c0-1.49 1.21-2.7 2.7-2.7 1.49 0 2.7 1.21 2.7 2.7V22H17c1.1 0 2-.9 2-2v-4h1.5a2.5 2.5 0 000-5z" fill="#88BF80"/><path d="M13 5V3.5a2.5 2.5 0 00-5 0V5h5zM19 16h1.5a2.5 2.5 0 000-5H19v5z" fill="#C3DFBF"/></svg>

</template>


<script>

  export default {"name":"icon-a60ec383288e1d715e761d16111b6833"}

</script>