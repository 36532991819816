<template>

  <svg viewBox="0 0 24 24" role="presentation" focusable="false" width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.99 6.91c.39.38 1.03.38 1.41 0a.996.996 0 000-1.41l-.71-.71A.996.996 0 105.28 6.2l.71.71zM5 12c0-.55-.45-1-1-1H3c-.55 0-1 .45-1 1s.45 1 1 1h1c.55 0 1-.45 1-1zm16 1c.55 0 1-.45 1-1s-.45-1-1-1h-1c-.55 0-1 .45-1 1s.45 1 1 1h1zm-2.99-6.09l.71-.71a.996.996 0 10-1.41-1.41l-.71.71a.996.996 0 101.41 1.41zM12 5c.55 0 1-.45 1-1V3c0-.55-.45-1-1-1s-1 .45-1 1v1c0 .55.44 1 1 1z" fill="#F8C529" fill-opacity=".5"/><path d="M15 15.98H9c-1.43-1.08-2.28-2.9-1.91-4.91.36-1.95 1.9-3.55 3.84-3.95A4.995 4.995 0 0117 12c0 1.63-.79 3.06-2 3.98zM12 22c1.1 0 2-.9 2-2h-4c0 1.1.9 2 2 2zm-2.25-5c-.412 0-.75.45-.75 1s.338 1 .75 1h4.5c.412 0 .75-.45.75-1s-.338-1-.75-1h-4.5z" fill="#F8C529"/></svg>

</template>


<script>

  export default {"name":"icon-7b22cfcacf814b9212572573fc31c13a"}

</script>