<template>

  <svg role="presentation" focusable="false" xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M280-400l200-200 200 200H280z"/></svg>

</template>


<script>

  export default {"name":"icon-b55570c35bc45ea258e83d6d61e84450"}

</script>