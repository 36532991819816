<template>

  <span :style="{ color: $themeTokens.textDisabled }">—</span>

</template>


<script>

  export default {
    name: 'KEmptyPlaceholder',
  };

</script>


<style lang="scss" scoped></style>
