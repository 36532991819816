<template>

  <svg viewBox="0 0 24 24" role="presentation" focusable="false" xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z"/></svg>

</template>


<script>

  export default {"name":"icon-bfafb8a7aa4ec09750ff91a03f7690cd"}

</script>