<template>

  <svg viewBox="0 0 24 24" role="presentation" focusable="false" xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path d="M19 13H5v-2h14v2z"/></svg>

</template>


<script>

  export default {"name":"icon-9c5f6f373bc78e770dbfe5ca86b4ca7b"}

</script>