<template>

  <svg viewBox="0 0 24 24" role="presentation" focusable="false" xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path d="M14 4H4v10H2V4a2 2 0 012-2h10v2m4 2H8a2 2 0 00-2 2v10h2V8h10V6m4 6v8a2 2 0 01-2 2h-8a2 2 0 01-2-2v-8a2 2 0 012-2h8a2 2 0 012 2m-2 3h-8v2h8v-2z"/></svg>

</template>


<script>

  export default {"name":"icon-a331b2456f05914e927ccb704898d0be"}

</script>