<template>

  <svg role="presentation" focusable="false" width="24" height="24" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16.5 5.833h-10c-1.833 0-3.5 1.5-3.5 3.5v21.334c0 2 1.5 3.5 3.5 3.5h10c2 0 3.5-1.5 3.5-3.5V9.333c0-2-1.5-3.5-3.5-3.5zm.167 24.834c0 .166 0 .166 0 0L6.5 30.833s-.167 0-.167-.166v-2.334H10c.833 0 1.667-.833 1.667-1.666 0-.834-.834-1.667-1.667-1.667H6.333v-3.333H12.5c.833 0 1.667-.834 1.667-1.667 0-.833-.834-1.666-1.667-1.666H6.333V15H10c.833 0 1.667-.833 1.667-1.666 0-.834-.834-1.667-1.667-1.667H6.333V9.334s0-.167.167-.167h10s.167 0 .167.167v21.333zM29.833 24.167c1.834 0 3.334 1.5 3.334 3.333 0 1.834-1.5 3.334-3.334 3.334A3.343 3.343 0 0126.5 27.5c0-1.833 1.5-3.333 3.333-3.333zm0-3.334c-3.666 0-6.666 3-6.666 6.667s3 6.667 6.666 6.667c3.667 0 6.667-3 6.667-6.667 0-3.666-3-6.666-6.667-6.666zM29.834 11l2.5 4.5h-5.167l2.666-4.5zm0-5c-.5 0-1.167.333-1.5.833l-5.5 9.5c-.667 1.167.166 2.5 1.5 2.5h10.833c1.333 0 2-1.333 1.5-2.5l-5.5-9.5C31 6.167 30.333 6 29.833 6z"/></svg>

</template>


<script>

  export default {"name":"icon-6efc3c5abf00a6f96b864830803aff01"}

</script>