<template>

  <svg role="presentation" focusable="false" width="24" height="24" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.333 5A3.333 3.333 0 0010 8.333V15a3.333 3.333 0 01-3.333 3.333H5v3.334h1.667A3.333 3.333 0 0110 25v6.667A3.333 3.333 0 0013.333 35h3.334v-3.333h-3.334v-8.334A3.333 3.333 0 0010 20a3.333 3.333 0 003.333-3.333V8.333h3.334V5h-3.334zm13.334 0A3.333 3.333 0 0130 8.333V15a3.333 3.333 0 003.333 3.333H35v3.334h-1.667A3.333 3.333 0 0030 25v6.667A3.333 3.333 0 0126.667 35h-3.334v-3.333h3.334v-8.334A3.333 3.333 0 0130 20a3.333 3.333 0 01-3.333-3.333V8.333h-3.334V5h3.334z"/></svg>

</template>


<script>

  export default {"name":"icon-ce53342dd5df75950e741d842bc3a703"}

</script>